<template>
  <v-row>

    <v-col cols="12" lg="12" sm="12" align="center">
      <v-alert color="primary" dark icon="mdi-account-credit-card-outline" border="left" prominent>
        <div class="font-weight-bold">
          Compra directamente desde tu computadora, celular o tablet. Inicia sesión en tu cuenta de paypal!
        </div>
      </v-alert>
    </v-col>

    <v-col cols="12" md="12" sm="12" align="center" class="py-9">
      <v-progress-circular
        :size="200"
        :width="8"
        color="primary"
        indeterminate
        v-if="crediting"
      >
        <b>Acreditando el pago!</b>
      </v-progress-circular>

      <div id="paypal-button-container"></div>

      <p></p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      crediting: false,
    };
  },
  mounted() {
    this.getButtons();
  },
  methods: {
    getButtons() {
      let ocId = this.order.ocId;

      let tblCnfCuentaContacto =
        _.get(this.order, "tblCnfCuentaContacto") || {};

      let email = this.order.email;
      let national_number = this.order.telefono;
      let country_code = tblCnfCuentaContacto.paisOrigen || "MX";

      const paypalButtonsComponent = paypal.Buttons({
        // OPTIONAL STYLING FOR BUTTONS
        style: {
          color: "white",
          shape: "rect",
          layout: "vertical",
        },

        // SET UP THE TRANSACTION
        createOrder: (data, actions) => {
          // PASS IN ANY OPTIONS FROM THE V2 ORDERS CREATE CALL
          const createOrderPayload = {
            purchase_units: [
              {
                amount: {
                  currency_code: "MXN",
                  value: (this.order.total || 0).toFixed(2),
                },
                custom_id: ocId,
              },
            ],
            application_context: {
              shipping_preference: "NO_SHIPPING",
            },
            payer: {
              // "name": {
              //   "given_name": given_name
              // },
              email_address: email,
              phone: {
                phone_number: {
                  national_number: national_number,
                },
              },
              address: {
                //   "address_line_1": "Convento del Carmen H13",
                //   "address_line_2": "Hogares Ferrocarrileros",
                //   "admin_area_2": "Tlalnepantla",
                //   "admin_area_1": "MEX",
                //   "postal_code": "54090",
                country_code: country_code,
              },
            },
          };

          return actions.order.create(createOrderPayload);
        },

        // FINALIZE THE TRANSACTION
        onApprove: (data, actions) => {
          const captureOrderHandler = (details) => {
            const payerName = details.payer.name.given_name;
            this.crediting = true;
            // Show a success message within this page, e.g.
            const element = document.getElementById("paypal-button-container");
            element.innerHTML = "";

            this.$console.log("Transaction completed", payerName);
          };

          return actions.order.capture().then(captureOrderHandler);
        },

        // HANDLE UNRECOVERABLE ERRORS
        onError: (err) => {
          this.$console.error(
            "An error prevented the buyer from checking out with PayPal"
          );
        },
      });

      paypalButtonsComponent.render("#paypal-button-container").catch((err) => {
        this.$console.error("PayPal Buttons failed to render");
      });
    },
  },
};
</script>